<!--健康指数说明-->
<template>
  <el-dialog
    v-if="showDialog"
    title="健康指数评分说明"
		:visible.sync="dialogVisible"
    :append-to-body="true"
    custom-class="health-index-explain-dialog"
    width="80%"
    @close="onCloseDialog">
    <div class="clearfix">
      <section class="fl section-left">
        <div class="one section-part">
          <h4 class="title">一、安全性</h4>
          <p class="subtext">对标： 风险项（权重：30%）</p>
          <span class="text">1.风险项比率 = 含有风险项数据条目数 ÷ 所有药品条目数(包含未匹配和不匹配的药品)</span>
          <span class="text">2.根据风险项比率评分得出安全性分数：</span>
          <el-table
            :data="riskSectionTableData"
            border
            class="table-class"
            style="width: 45%"
            :header-cell-style="{textAlign: 'center'}"
            :cell-style="{textAlign: 'center'}">
            <el-table-column prop="percent" label="风险项比率"></el-table-column>
            <el-table-column prop="value" label="得分"></el-table-column>
          </el-table>
        </div>
        <div class="three section-part">
          <h4 class="title">三、完整性</h4>
          <p class="subtext">对标： 数据缺失（权重：30%）</p>
          <span class="text">1.过滤掉非药品</span>
          <span class="text">2.缺失率 = 无数据字段数目÷ 所需上传字段总数</span>
          <span class="text">3.根据数据缺失项比率评分得出完整性分数： (1 - 缺失率) × 100</span>
        </div>
        <div class="five section-part">
          <h4 class="title title-border">健康指数</h4>
          <p class="subtext">健康指数 = 安全性 × 30% + 准确性 × 30% + 完整性 × 30% + 匹配性 × 10%</p>
          <el-table
            :data="healthIndexTableData"
            border
            class="table-class helath-index-table"
            style="width: 45%"
            :header-cell-style="{textAlign: 'center'}"
            :cell-style="{textAlign: 'center'}"
            :cell-class-name="healthIndexTableCell">
            <el-table-column prop="rank" label="分数区间"></el-table-column>
            <el-table-column prop="level" label="等级">
              <template slot-scope="scope">
                <!-- <span>{{ scope.row.level }}</span> -->
                <img :src="scope.row.src" />
              </template>
            </el-table-column>
          </el-table>
        </div>
      </section>
      <section class="fl section-right">
        <div class="two section-part">
          <h4 class="title">二、准确性</h4>
          <p class="subtext">对标： 优化项（权重：30%）</p>
          <span class="text">1.优化项比率 = 含有优化项数据条目数 ÷ 所有药品条目数(包含未匹配和不匹配的药品)</span>
          <span class="text">2.根据优化项比率评分得出准确性分数：</span>
          <el-table
            :data="riskSectionTableData"
            border
            class="table-class"
            style="width: 45%"
            :header-cell-style="{textAlign: 'center'}"
            :cell-style="{textAlign: 'center'}">
            <el-table-column prop="percent" label="风险项比率"></el-table-column>
            <el-table-column prop="value" label="得分"></el-table-column>
          </el-table>
        </div>
        <div class="four section-part">
          <h4 class="title">四、匹配性</h4>
          <p class="subtext">对标： 数据匹配（权重：10%）</p>
          <span class="text">1.匹配率 = 已匹配数据条目数 ÷ （总条目数 - 非药品条目数 - 药品库无数据原因未匹
            配的条目数）</span>
          <span class="text">2.根据数据匹配项比率评分得出匹配性分数： 匹配率 × 100</span>
        </div>
      </section>
    </div>
  </el-dialog>
</template>
<script>
  export default {
    props: {
      showDialog: {
        type: Boolean,
        default: false
      }
    },
    watch: {
      showDialog(newVal, oldVal) {
        if (newVal) {
          this.dialogVisible = true
        }
      }
    },
    data() {
      return {
        dialogVisible: false,
        riskSectionTableData: [
          {
            percent: '=0%',
            value: 100
          },
          {
            percent: '<=2%',
            value: 80
          },
          {
            percent: '<=5%',
            value: 60
          },
          {
            percent: '<=10%',
            value: 50
          },
          {
            percent: '>10%',
            value: 30
          }
        ],
        healthIndexTableData: [
          {
            rank: '健康指数>=90',
            level: '优',
            src: require('../../assets/images/happy-emoji.png')
          },
          {
            rank: '80<=健康指数<90',
            level: '良',
            src: require('../../assets/images/liang-emoji.png')
          },
          {
            rank: '60<=健康指数<80',
            level: '中',
            src: require('../../assets/images/zhong-emoji.png')
          },
          {
            rank: '健康指数<60',
            level: '差',
            src: require('../../assets/images/sad-emoji.png')
          }
        ]
      }
    },
    created() {
      // this.dialogVisible = true
    },
    methods: {
      healthIndexTableCell({row, column, rowIndex, columnIndex}) {
        if (row.level === '优' && column.property === 'level') {
          return 'you-td level-td'
        } else if (row.level === '良' && column.property === 'level') {
          return 'liang-td level-td'
        } else if (row.level === '中' && column.property === 'level') {
          return 'zhong-td level-td'
        }if (row.level === '差' && column.property === 'level') {
          return 'cha-td level-td'
        }
      },
      onCloseDialog() {
        this.$emit('update:showDialog', false)
      }
    }
  }
</script>
<style lang="scss">
.health-index-explain-dialog {
  height: 80%;
  .el-dialog__body {
    padding: 20px !important;
    overflow: auto;
    height: calc(100% - 40px);
  }
  .section-left {
    width: 50%;
  }
  .section-right {
    width: 50%;
  }
  .section-part {
    margin-bottom: 10px;
    &:last-child{
      margin-bottom: 0;
    }
  }
  .title {
    font-size: 16px;
    font-family: PingFang SC;
    font-weight: 600;
    color: #333333;
    margin-bottom: 10px;
    &.title-border {
      display: inline-block;
      border-bottom: 4px solid #4A78F7;
      border-radius: 2px;
    }
  }
  .subtext {
    font-size: 14px;
    font-family: PingFang SC;
    font-weight: 500;
    color: #333333;
    margin-bottom: 10px;
  }
  .text {
    display: block;
    width: 100%;
    font-size: 14px;
    font-family: PingFang SC;
    font-weight: 400;
    color: #666666;
  }
  .table-class {
    margin-top: 10px;
    &.helath-index-table {
      .level-td {
        .cell {
          span {
            display: inline-block;
            width: 24px;
            height: 24px;
            line-height: 24px;
            font-size: 14px;
            border-radius: 50%;
          }
        }
      }
      .you-td {
        .cell {
          span {
            color: #00AB44;
            border: 1px solid #00AB44;
          }
        }
      }
      .liang-td {
        .cell {
          span {
            color: #2D5AFA;
            border: 1px solid #2D5AFA;
            line-height: 22px;
          }
        }
      }
      .zhong-td {
        .cell {
          span {
            color: #FF8C00;
            border: 1px solid #FF8C00;
          }
        }
      }
      .cha-td {
        .cell {
          span {
            color: #EC0000;
            border: 1px solid #EC0000;
          }
        }
      }
    }
  }
}
</style>