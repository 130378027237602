var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.deptType !== 1 && _vm.deptType !== 8
    ? _c("div", { staticClass: "app-container", attrs: { id: "indexbg" } }, [
        _c("img", {
          staticClass: "indexImg",
          attrs: { src: require("../assets/images/index_bg.png"), alt: "" },
        }),
      ])
    : _vm.deptType === 1
    ? _c("insti-home-page")
    : _vm.deptType === 8
    ? _c("klib-home-page")
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }