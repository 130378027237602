<template>
  <!--非医疗机构首页-->
  <div v-if="deptType !== 1 && deptType !== 8" id="indexbg" class="app-container">
    <img src="../assets/images/index_bg.png" alt="" class="indexImg"/>
    <!--    <el-row :gutter="20">-->
    <!--      <el-col :sm="24" :lg="24">-->
    <!--        <blockquote class="text-warning" style="font-size: 14px">-->
    <!--          <h2 class="text-danger">-->
    <!--            欢迎使用 PDBox-药数矩阵-->
    <!--          </h2>-->
    <!--        </blockquote>-->

    <!--        <hr />-->
    <!--      </el-col>-->
    <!--    </el-row>-->
    <!--    <panel-group @handleSetLineChartData="handleSetLineChartData" />-->

    <!--    <el-row style="background:#fff;padding:16px 16px 0;margin-bottom:32px;">-->
    <!--      <line-chart :chart-data="lineChartData" />-->
    <!--    </el-row>-->

    <!--    <el-row :gutter="32">-->
    <!--      <el-col :xs="24" :sm="24" :lg="8">-->
    <!--        <div class="chart-wrapper">-->
    <!--          <raddar-chart />-->
    <!--        </div>-->
    <!--      </el-col>-->
    <!--      <el-col :xs="24" :sm="24" :lg="8">-->
    <!--        <div class="chart-wrapper">-->
    <!--          <pie-chart />-->
    <!--        </div>-->
    <!--      </el-col>-->
    <!--      <el-col :xs="24" :sm="24" :lg="8">-->
    <!--        <div class="chart-wrapper">-->
    <!--          <bar-chart />-->
    <!--        </div>-->
    <!--      </el-col>-->
    <!--    </el-row>-->
  </div>
  <!--医疗机构的首页-->
  <insti-home-page v-else-if="deptType === 1" />
  <!--klib的首页-->
  <klib-home-page v-else-if="deptType === 8" />
</template>

<script>
import PanelGroup from './dashboard/PanelGroup'
import LineChart from './dashboard/LineChart'
import RaddarChart from './dashboard/RaddarChart'
import PieChart from './dashboard/PieChart'
import BarChart from './dashboard/BarChart'
import InstiHomePage from '../layout/InstiHomeMain'
import KlibHomePage from '../layout/KlibHomeMain.vue'
const lineChartData = {
  newVisitis: {
    expectedData: [100, 120, 161, 134, 105, 160, 165],
    actualData: [120, 82, 91, 154, 162, 140, 145]
  },
  messages: {
    expectedData: [200, 192, 120, 144, 160, 130, 140],
    actualData: [180, 160, 151, 106, 145, 150, 130]
  },
  purchases: {
    expectedData: [80, 100, 121, 104, 105, 90, 100],
    actualData: [120, 90, 100, 138, 142, 130, 130]
  },
  shoppings: {
    expectedData: [130, 140, 141, 142, 145, 150, 160],
    actualData: [120, 82, 91, 154, 162, 140, 130]
  }
}

export default {
  name: 'Index',
  components: {
    InstiHomePage,
    PanelGroup,
    LineChart,
    RaddarChart,
    PieChart,
    BarChart,
    KlibHomePage
  },
  data() {
    return {
      lineChartData: lineChartData.newVisitis
    }
  },
  computed: {
    deptType() {
      return Number(this.$store.getters.dept.deptType)
    }
  },
  methods: {
    handleSetLineChartData(type) {
      this.lineChartData = lineChartData[type]
    },
    // 医疗机构首页，没有数据的时候上传数据事件
    toUploadData() {

    }
  }
}
</script>

<style lang="scss" scoped>
#indexbg {
  //width: 100%;
  height: 100%;
  background:#F6FBFF;
  //background: #BDD3FF;
  background-size: 100% 100%;
  width: 100%;
  position: absolute;
  .indexImg {
    position: absolute;
    top: 40px;
    left: 50%;
    height: 78%;
    //margin-left:-365px;
    transform: translate(-50%,0);
  }
}
</style>
