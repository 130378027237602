var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-container",
    { staticClass: "layout-container home-page-container" },
    [
      !_vm.hasData
        ? _c("el-main", { staticClass: "home-page-none-data" }, [
            _c(
              "div",
              { staticClass: "none-data-wrap" },
              [
                _c("span", [_vm._v("即刻开启您的数据治理之旅")]),
                _c(
                  "el-button",
                  {
                    staticClass: "upload-btn",
                    attrs: { type: "primary", size: "large" },
                    on: { click: _vm.goToUploadData },
                  },
                  [_vm._v("上传数据")]
                ),
              ],
              1
            ),
          ])
        : _c(
            "el-container",
            { staticClass: "layout-container data-container" },
            [
              _c(
                "el-aside",
                { staticClass: "data-left" },
                [
                  _c(
                    "el-container",
                    { staticClass: "layout-container box-shadow-padding" },
                    [
                      _c(
                        "el-main",
                        { staticClass: "data-left-top box-shadow" },
                        [
                          _c(
                            "header",
                            {
                              staticClass: "title-header data-left-top-header",
                            },
                            [
                              _c(
                                "h3",
                                [
                                  _c("span", [_vm._v("药品元数据健康指数")]),
                                  _c("el-button", {
                                    staticStyle: { "font-size": "18px" },
                                    attrs: {
                                      type: "text",
                                      size: "medium",
                                      icon: "el-icon-info",
                                    },
                                    on: { click: _vm.onDialogClick },
                                  }),
                                ],
                                1
                              ),
                              _vm.chartObj.updateTime
                                ? _c("h4", [
                                    _c("span", [_vm._v("该数据更新于")]),
                                    _c("span", [
                                      _vm._v(_vm._s(_vm.chartObj.updateTime)),
                                    ]),
                                  ])
                                : _vm._e(),
                              _c("health-index-explain", {
                                attrs: {
                                  "show-dialog": _vm.showHealthIndexDialog,
                                },
                                on: {
                                  "update:showDialog": function ($event) {
                                    _vm.showHealthIndexDialog = $event
                                  },
                                  "update:show-dialog": function ($event) {
                                    _vm.showHealthIndexDialog = $event
                                  },
                                },
                              }),
                            ],
                            1
                          ),
                          _c("div", { staticClass: "chart-explain-div" }, [
                            Number(_vm.chartStatusObj.status) === 4
                              ? _c(
                                  "ul",
                                  {
                                    staticClass:
                                      "chart-view-indicator-ul clearfix",
                                  },
                                  [
                                    _c("li", {
                                      staticClass: "chart-view-indicator-li fl",
                                      class: {
                                        active: _vm.currentChartIndex === 0,
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.chartChange(0)
                                        },
                                      },
                                    }),
                                    _c("li", {
                                      staticClass: "chart-view-indicator-li fl",
                                      class: {
                                        active: _vm.currentChartIndex === 1,
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.chartChange(1)
                                        },
                                      },
                                    }),
                                  ]
                                )
                              : _vm._e(),
                            _c(
                              "section",
                              { staticClass: "chart-view-section" },
                              [
                                _c("div", {
                                  staticClass: "chart-view",
                                  attrs: { id: "chart-view" },
                                }),
                              ]
                            ),
                            _c(
                              "section",
                              { staticClass: "chart-explain-section" },
                              [
                                Number(_vm.chartStatusObj.status) === 4
                                  ? _c(
                                      "div",
                                      {
                                        staticStyle: {
                                          "margin-bottom": "10px",
                                        },
                                      },
                                      [
                                        _c(
                                          "p",
                                          {
                                            staticClass:
                                              "chart-explain-1 chart-explain",
                                          },
                                          [
                                            _c("img", {
                                              staticStyle: {
                                                float: "left",
                                                "margin-right": "5px",
                                              },
                                              attrs: {
                                                src: _vm.healthIndexEmoji,
                                              },
                                            }),
                                            _c(
                                              "span",
                                              { staticClass: "text" },
                                              [_vm._v("健康指数与上次相比：")]
                                            ),
                                            _vm.chartObj.indexCompareTrend !== 0
                                              ? _c(
                                                  "span",
                                                  [
                                                    _c(
                                                      "span",
                                                      { staticClass: "number" },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm._f(
                                                              "compareVal"
                                                            )(
                                                              _vm.chartObj
                                                                .indexCompareAbsoluteValue,
                                                              _vm.chartObj
                                                                .indexCompareTrend
                                                            )
                                                          )
                                                        ),
                                                      ]
                                                    ),
                                                    _vm.chartObj
                                                      .indexCompareTrend ===
                                                      1 ||
                                                    _vm.chartObj
                                                      .indexCompareTrend === -1
                                                      ? _c("svg-icon", {
                                                          staticClass:
                                                            "babu-svg",
                                                          attrs: {
                                                            "icon-class":
                                                              _vm.trendIcon,
                                                            width: "56px",
                                                            height: "56px",
                                                          },
                                                        })
                                                      : _vm._e(),
                                                  ],
                                                  1
                                                )
                                              : _c(
                                                  "span",
                                                  { staticClass: "number" },
                                                  [_vm._v("持平")]
                                                ),
                                          ]
                                        ),
                                        _vm.chartStatusObj.link
                                          ? _c(
                                              "p",
                                              {
                                                staticClass:
                                                  "chart-explain chart-explain-subtext",
                                                staticStyle: {
                                                  "text-align": "left",
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.linkHandle(
                                                      _vm.chartStatusObj
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _c("svg-icon", {
                                                  attrs: {
                                                    "icon-class": "handPoint",
                                                  },
                                                }),
                                                _c(
                                                  "span",
                                                  { class: _vm.linkTextShow },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.chartStatusObj.link
                                                      )
                                                    ),
                                                  ]
                                                ),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                      ]
                                    )
                                  : _c(
                                      "p",
                                      {
                                        staticClass:
                                          "chart-explain-2 chart-explain",
                                        on: {
                                          click: function ($event) {
                                            return _vm.linkHandle(
                                              _vm.chartStatusObj
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _c("svg-icon", {
                                          attrs: { "icon-class": "handPoint" },
                                        }),
                                        _c(
                                          "span",
                                          { class: _vm.linkTextShow },
                                          [
                                            _vm._v(
                                              _vm._s(_vm.chartStatusObj.link)
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                              ]
                            ),
                          ]),
                        ]
                      ),
                      _c("el-footer", { staticClass: "data-left-bottom" }, [
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "preventReClick",
                                rawName: "v-preventReClick",
                              },
                            ],
                            staticClass: "data-left-bottom-left box-shadow",
                            on: {
                              click: function ($event) {
                                $event.stopPropagation()
                                $event.currentTarget.disabled
                                  ? ""
                                  : _vm.goToViewReport()
                              },
                            },
                          },
                          [
                            _c("span", [_vm._v("查看治理报告")]),
                            _c("div", { staticClass: "icon-img" }),
                          ]
                        ),
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "preventReClick",
                                rawName: "v-preventReClick",
                              },
                            ],
                            staticClass: "data-left-bottom-right box-shadow",
                            on: {
                              click: function ($event) {
                                $event.stopPropagation()
                                $event.currentTarget.disabled
                                  ? ""
                                  : _vm.goToUploadData()
                              },
                            },
                          },
                          [
                            _c("span", [_vm._v("上传数据")]),
                            _c("div", { staticClass: "icon-img" }),
                          ]
                        ),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-main",
                { staticClass: "data-right" },
                [
                  _c(
                    "el-container",
                    {
                      staticClass: "layout-container",
                      staticStyle: {
                        display: "flex",
                        "flex-direction": "column",
                      },
                    },
                    [
                      _c(
                        "el-header",
                        {
                          staticClass: "data-right-top",
                          staticStyle: { padding: "4px" },
                        },
                        [
                          _c(
                            "section",
                            {
                              staticClass: "box-shadow",
                              staticStyle: {
                                height: "100%",
                                padding: "20px",
                                display: "flex",
                                "flex-direction": "column",
                                "justify-content": "space-between",
                              },
                            },
                            [
                              _c(
                                "header",
                                {
                                  staticClass:
                                    "title-header data-right-top-header",
                                },
                                [_c("h3", [_vm._v("任务统计")])]
                              ),
                              _c("ul", { staticClass: "task-ul" }, [
                                _c(
                                  "li",
                                  {
                                    staticClass:
                                      "task-li box-shadow to-handle-li",
                                  },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "tag-text-wrap" },
                                      [
                                        _c("h3", [
                                          _vm._v(_vm._s(_vm.taskObj.pendCount)),
                                        ]),
                                        _c("p", { staticClass: "tag-text" }, [
                                          _vm._v("待处理"),
                                        ]),
                                      ]
                                    ),
                                    _c("div", { staticClass: "tag-icon" }, [
                                      _c("img", {
                                        attrs: {
                                          src: require("../assets/images/institution/to-handle.png"),
                                        },
                                      }),
                                    ]),
                                  ]
                                ),
                                _c(
                                  "li",
                                  {
                                    staticClass:
                                      "task-li box-shadow handleing-li",
                                  },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "tag-text-wrap" },
                                      [
                                        _c("h3", [
                                          _vm._v(
                                            _vm._s(_vm.taskObj.processCount)
                                          ),
                                        ]),
                                        _c("p", { staticClass: "tag-text" }, [
                                          _vm._v("处理中"),
                                        ]),
                                      ]
                                    ),
                                    _c("div", { staticClass: "tag-icon" }, [
                                      _c("img", {
                                        attrs: {
                                          src: require("../assets/images/institution/handleing.png"),
                                        },
                                      }),
                                    ]),
                                  ]
                                ),
                                _c(
                                  "li",
                                  {
                                    staticClass: "task-li box-shadow finish-li",
                                  },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "tag-text-wrap" },
                                      [
                                        _c("h3", [
                                          _vm._v(
                                            _vm._s(_vm.taskObj.finishCount)
                                          ),
                                        ]),
                                        _c("p", { staticClass: "tag-text" }, [
                                          _vm._v("处理完成"),
                                        ]),
                                      ]
                                    ),
                                    _c("div", { staticClass: "tag-icon" }, [
                                      _c("img", {
                                        attrs: {
                                          src: require("../assets/images/institution/handled.png"),
                                        },
                                      }),
                                    ]),
                                  ]
                                ),
                              ]),
                            ]
                          ),
                        ]
                      ),
                      _c("el-main", { staticClass: "data-right-bottom" }, [
                        _c(
                          "div",
                          { staticClass: "data-right-bottom-left box-shadow" },
                          [
                            _c(
                              "header",
                              {
                                staticClass:
                                  "title-header data-right-bottom-left-header",
                              },
                              [_c("h3", [_vm._v("事件记录")])]
                            ),
                            _c(
                              "section",
                              { staticClass: "event-list-section" },
                              _vm._l(_vm.eventList, function (item, index) {
                                return _c(
                                  "ul",
                                  { key: index, staticClass: "event-list-ul" },
                                  [
                                    _c(
                                      "li",
                                      {
                                        staticClass: "event-list-li-left",
                                        staticStyle: { position: "relative" },
                                      },
                                      [
                                        _c(
                                          "span",
                                          {
                                            staticStyle: {
                                              display: "inline-block",
                                              width: "36px",
                                              height: "36px",
                                              float: "left",
                                              "margin-right": "5px",
                                            },
                                          },
                                          [
                                            _c(
                                              "el-badge",
                                              {
                                                staticClass: "event-badge",
                                                attrs: {
                                                  "is-dot":
                                                    _vm.hasEventIdot(item),
                                                },
                                              },
                                              [
                                                item.operationType === 2
                                                  ? _c("img", {
                                                      attrs: {
                                                        src: require("../assets/images/institution/touxiang.png"),
                                                      },
                                                    })
                                                  : _c("img", {
                                                      attrs: {
                                                        src: require("../assets/images/institution/platform-profile.png"),
                                                      },
                                                    }),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "p",
                                          {
                                            staticClass: "username-text",
                                            attrs: { title: item.operatorName },
                                          },
                                          [_vm._v(_vm._s(item.operatorName))]
                                        ),
                                        _c(
                                          "p",
                                          {
                                            staticClass: "time-text",
                                            attrs: { title: item.operateTime },
                                          },
                                          [_vm._v(_vm._s(item.operateTime))]
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "li",
                                      { staticClass: "event-list-li-right" },
                                      [
                                        Number(item.logType) === 3 ||
                                        Number(item.logType) === 7
                                          ? _c(
                                              "el-tooltip",
                                              {
                                                attrs: {
                                                  placement: "top",
                                                  effect: "light",
                                                  content: "下载标准药品库",
                                                },
                                              },
                                              [
                                                _c(
                                                  "span",
                                                  { staticClass: "tag-icon" },
                                                  [
                                                    _c("i", {
                                                      staticClass:
                                                        "el-icon-download el-icon",
                                                      attrs: {
                                                        title: "下载标准药品库",
                                                      },
                                                    }),
                                                  ]
                                                ),
                                              ]
                                            )
                                          : Number(item.logType) === 6
                                          ? _c(
                                              "el-tooltip",
                                              {
                                                attrs: {
                                                  placement: "top",
                                                  effect: "light",
                                                  content: "查看治理报告",
                                                },
                                              },
                                              [
                                                _c(
                                                  "span",
                                                  { staticClass: "tag-icon" },
                                                  [
                                                    _c("i", {
                                                      staticClass:
                                                        "el-icon-view el-icon",
                                                    }),
                                                  ]
                                                ),
                                              ]
                                            )
                                          : _c(
                                              "span",
                                              { staticClass: "tag-icon" },
                                              [
                                                _c("i", {
                                                  staticClass:
                                                    "el-icon-news el-icon",
                                                }),
                                              ]
                                            ),
                                        _c(
                                          "span",
                                          {
                                            staticClass: "event-text",
                                            class: _vm.noticeEventText(item),
                                            attrs: { title: item.logMsg },
                                            on: {
                                              click: function ($event) {
                                                return _vm.onEventLink(item)
                                              },
                                            },
                                          },
                                          [
                                            _vm._v(
                                              " " + _vm._s(item.logMsg) + " "
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                  ]
                                )
                              }),
                              0
                            ),
                          ]
                        ),
                        _c(
                          "div",
                          { staticClass: "data-right-bottom-right box-shadow" },
                          [
                            _c(
                              "header",
                              {
                                staticClass:
                                  "title-header data-right-bottom-right-header",
                              },
                              [_c("h3", [_vm._v("公告")])]
                            ),
                            _c(
                              "section",
                              { staticClass: "notice-list-section" },
                              [
                                _c(
                                  "ul",
                                  _vm._l(_vm.noticeList, function (item) {
                                    return _c(
                                      "li",
                                      { key: item.id },
                                      [
                                        _c(
                                          "el-badge",
                                          {
                                            staticClass: "item",
                                            staticStyle: {
                                              float: "left",
                                              "margin-right": "6px",
                                            },
                                            attrs: {
                                              "is-dot": _vm.hasReadNotice(item),
                                            },
                                          },
                                          [
                                            _c("img", {
                                              attrs: {
                                                src: require("../assets/images/gonggao.png"),
                                              },
                                            }),
                                          ]
                                        ),
                                        _c(
                                          "h4",
                                          {
                                            staticStyle: {
                                              "margin-left": "5px",
                                            },
                                            attrs: { title: item.title },
                                            on: {
                                              click: function ($event) {
                                                return _vm.onNoticeClick(item)
                                              },
                                            },
                                          },
                                          [_vm._v(_vm._s(item.title))]
                                        ),
                                        _c(
                                          "p",
                                          {
                                            staticStyle: {
                                              "margin-left": "28px",
                                            },
                                          },
                                          [
                                            _c("i", {
                                              staticClass: "el-icon-time",
                                            }),
                                            _c("span", [
                                              _vm._v(_vm._s(item.pubTime)),
                                            ]),
                                          ]
                                        ),
                                      ],
                                      1
                                    )
                                  }),
                                  0
                                ),
                              ]
                            ),
                          ]
                        ),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }