<!--医疗机构的首页-->
<template>
  <el-container class="layout-container home-page-container">
    <el-main v-if="!hasData" class="home-page-none-data">
      <div class="none-data-wrap">
        <!-- <img src="../assets/images/institution/bj.png" alt="" class="indexImg"/> -->
        <span>即刻开启您的数据治理之旅</span>
        <el-button type="primary" size="large" class="upload-btn" @click="goToUploadData">上传数据</el-button>
      </div>
    </el-main>
    <el-container v-else class="layout-container data-container">
      <el-aside class="data-left">
        <el-container class="layout-container box-shadow-padding">
          <el-main class="data-left-top box-shadow">
            <header class="title-header data-left-top-header">
              <h3>
                <span>药品元数据健康指数</span>
                <el-button
                  type="text"
                  size="medium"
                  style="font-size: 18px;"
                  icon="el-icon-info"
                  @click="onDialogClick"/>
              </h3>
              
              <h4 v-if="chartObj.updateTime">
                <span>该数据更新于</span>
                <span>{{ chartObj.updateTime }}</span>
              </h4>
              <health-index-explain :show-dialog.sync="showHealthIndexDialog" />
            </header>
          <div class="chart-explain-div">
            <!--图表切换触发器-->
            <ul v-if="Number(chartStatusObj.status) === 4" class="chart-view-indicator-ul clearfix">
              <li class="chart-view-indicator-li fl" :class="{ 'active': currentChartIndex === 0}" @click="chartChange(0)"></li>
              <li class="chart-view-indicator-li fl" :class="{ 'active': currentChartIndex === 1}" @click="chartChange(1)"></li>
            </ul>
            <section class="chart-view-section">
              <div id="chart-view" class="chart-view"></div>
            </section>
            <section class="chart-explain-section">
              <div v-if="Number(chartStatusObj.status) === 4" style="margin-bottom: 10px;">
                <p class="chart-explain-1 chart-explain">
                  <img :src="healthIndexEmoji" style="float: left;margin-right: 5px;" />
                  <span class="text">健康指数与上次相比：</span>
                  <span v-if="chartObj.indexCompareTrend !== 0">
                    <span class="number">{{ chartObj.indexCompareAbsoluteValue | compareVal(chartObj.indexCompareTrend) }}</span>
                    <!-- <i v-if="chartObj.indexCompareTrend === 1" class="el-icon-top icon" />
                    <i v-else-if="chartObj.indexCompareTrend === -1" class="el-icon-bottom icon" /> -->
                    <svg-icon
                      v-if="chartObj.indexCompareTrend === 1 || chartObj.indexCompareTrend === -1"
                      :icon-class="trendIcon"
                      class="babu-svg"
                      width="56px"
                      height="56px" />
                  </span>
                  <span v-else class="number">持平</span>
                  <!-- <i v-else-if="chartObj.indexCompareTrend === 0" class="el-icon-pie-chart icon" /> -->
                </p>
                <p
                  v-if="chartStatusObj.link"
                  class="chart-explain chart-explain-subtext"
                  style="text-align: left;"
                  @click="linkHandle(chartStatusObj)">
                  <svg-icon icon-class="handPoint" class="" />
                  <span :class="linkTextShow">{{ chartStatusObj.link }}</span>
                </p>
              </div>
              <p v-else class="chart-explain-2 chart-explain" @click="linkHandle(chartStatusObj)">
                <svg-icon icon-class="handPoint" class="" />
                <span :class="linkTextShow">{{ chartStatusObj.link }}</span>
              </p>
            </section>
          </div>
          </el-main>
          <el-footer class="data-left-bottom">
            <div
              class="data-left-bottom-left box-shadow"
              @click.stop="$event.currentTarget.disabled ? '' : goToViewReport()" 
              v-preventReClick>
              <span>查看治理报告</span>
              <div class="icon-img"></div>
            </div>
            <div
              class="data-left-bottom-right box-shadow"
              @click.stop="$event.currentTarget.disabled ? '' : goToUploadData()"
              v-preventReClick>
              <span>上传数据</span>
              <div class="icon-img"></div>
            </div>
          </el-footer>
        </el-container>
        <!-- <section class="data-left-top box-shadow">
          <header class="title-header data-left-top-header">
            <h3>我的药品库</h3>
            <h4>
              <span>平均处理时间：</span>
              <span>2021-08-09 12:04</span>
            </h4>
          </header>
          <div class="chart-explain-div">
            <ul v-if="Number(chartStatusObj.status) === 4" class="chart-view-indicator-ul clearfix">
              <li class="chart-view-indicator-li fl" :class="{ 'active': currentChartIndex === 0}" @click="chartChange(0)"></li>
              <li class="chart-view-indicator-li fl" :class="{ 'active': currentChartIndex === 1}" @click="chartChange(1)"></li>
            </ul>
            <section class="chart-view-section">
              <div id="chart-view" class="chart-view"></div>
            </section>
            <section class="chart-explain-section">
              <p class="chart-explain-1 chart-explain" style="margin-bottom: 10px;">
                <span class="text">药品库健康指数与上次相比：</span>
                <span class="number">50%</span>
                <i class="el-icon-top icon" />
              </p>
              <p class="chart-explain-2 chart-explain">
                <span class="text">药品库健康指数与上次相比：</span>
                <span class="number">50%</span>
                <i class="el-icon-top icon" />
              </p>
            </section>
          </div>
        </section>
        <section class="data-left-bottom">
          <div class="data-left-bottom-left box-shadow" @click="goToViewReport">
            <span>查看治理报告</span>
            <div class="icon-img"></div>
          </div>
          <div class="data-left-bottom-right box-shadow" @click="goToUploadData">
            <span>上传数据</span>
            <div class="icon-img"></div>
          </div>
        </section> -->
      </el-aside>
      <el-main class="data-right">
        <el-container class="layout-container" style="display: flex;flex-direction: column;">
          <!-- <section class="box-shadow-padding"
            style="height: 100%;display: flex;flex-direction: column;"> -->
            <el-header class="data-right-top" style="padding: 4px;">
              <section class="box-shadow" style="height: 100%;padding: 20px;display: flex;flex-direction: column;justify-content: space-between;">
                <header class="title-header data-right-top-header">
                  <h3>任务统计</h3>
                </header>
                <ul class="task-ul">
                  <li class="task-li box-shadow to-handle-li">
                    <div class="tag-text-wrap">
                      <h3>{{ taskObj.pendCount }}</h3>
                      <p class="tag-text">待处理</p>
                    </div>
                    <div class="tag-icon"><img src="../assets/images/institution/to-handle.png" /></div>
                  </li>
                  <li class="task-li box-shadow handleing-li">
                    <div class="tag-text-wrap">
                      <h3>{{ taskObj.processCount }}</h3>
                      <p class="tag-text">处理中</p>
                    </div>
                    <div class="tag-icon"><img src="../assets/images/institution/handleing.png" /></div>
                  </li>
                  <li class="task-li box-shadow finish-li">
                    <div class="tag-text-wrap">
                      <h3>{{ taskObj.finishCount }}</h3>
                      <p class="tag-text">处理完成</p>
                    </div>
                    <div class="tag-icon"><img src="../assets/images/institution/handled.png" /></div>
                  </li>
                </ul>
              </section>
              <!-- <header class="title-header data-right-top-header">
                <h3>任务统计</h3>
              </header>
              <ul class="task-ul">
                <li class="task-li box-shadow to-handle-li">
                  <div class="tag-text-wrap">
                    <h3>{{ taskObj.pendCount }}</h3>
                    <p class="tag-text">待处理</p>
                  </div>
                  <div class="tag-icon"><img src="../assets/images/institution/to-handle.png" /></div>
                </li>
                <li class="task-li box-shadow handleing-li">
                  <div class="tag-text-wrap">
                    <h3>{{ taskObj.processCount }}</h3>
                    <p class="tag-text">处理中</p>
                  </div>
                  <div class="tag-icon"><img src="../assets/images/institution/handleing.png" /></div>
                </li>
                <li class="task-li box-shadow finish-li">
                  <div class="tag-text-wrap">
                    <h3>{{ taskObj.finishCount }}</h3>
                    <p class="tag-text">处理完成</p>
                  </div>
                  <div class="tag-icon"><img src="../assets/images/institution/handled.png" /></div>
                </li>
              </ul> -->
            </el-header>
          <!-- </section> -->
          <el-main class="data-right-bottom">
            <div class="data-right-bottom-left box-shadow">
              <header class="title-header data-right-bottom-left-header">
                <h3>事件记录</h3>
              </header>
              <section class="event-list-section">
                <ul v-for="(item, index) in eventList" :key="index" class="event-list-ul">
                  <li class="event-list-li-left" style="position: relative;">
                    <span style="display: inline-block;width: 36px;height: 36px;float: left;margin-right: 5px;">
                      <!-- <img src="../assets/images/institution/touxiang.png" /> -->
                      <el-badge :is-dot="hasEventIdot(item)" class="event-badge">
                        <img v-if="item.operationType === 2" src="../assets/images/institution/touxiang.png" />
                        <img v-else src="../assets/images/institution/platform-profile.png" />
                      </el-badge>
                    </span>
                    <p class="username-text" :title="item.operatorName">{{ item.operatorName }}</p>
                    <p class="time-text" :title="item.operateTime">{{ item.operateTime }}</p>
                  </li>
                  <li class="event-list-li-right">
                    <el-tooltip
                      v-if="Number(item.logType) === 3 || Number(item.logType) === 7"
                      placement="top"
                      effect="light"
                      content="下载标准药品库">
                      <span class="tag-icon">
                        <i class="el-icon-download el-icon" title="下载标准药品库"></i>
                      </span>
                    </el-tooltip>
                    <el-tooltip
                      v-else-if="Number(item.logType) === 6"
                      placement="top"
                      effect="light"
                      content="查看治理报告">
                      <span class="tag-icon">
                        <i class="el-icon-view el-icon"></i>
                      </span>
                    </el-tooltip>
                    <span v-else class="tag-icon">
                      <i class="el-icon-news el-icon"></i>
                    </span>
                    <span
                      class="event-text"
                      :class="noticeEventText(item)"
                      :title="item.logMsg"
                      @click="onEventLink(item)">
                      {{ item.logMsg }}
                    </span>
                    <!-- <span v-if="Number(item.logType) === 3 || Number(item.logType) === 7" class="tag-icon">
                      <i class="el-icon-download el-icon" title="下载标准药品库"></i>
                    </span>
                    <span v-else-if="Number(item.logType) === 6" class="tag-icon">
                      <i class="el-icon-view el-icon"></i>
                    </span>
                    <span v-else class="tag-icon">
                      <i class="el-icon-news el-icon"></i>
                    </span>
                    <span class="event-text" :title="item.logMsg">{{ item.logMsg }}</span> -->
                  </li>
                </ul>
              </section>
            </div>
            <div class="data-right-bottom-right box-shadow">
              <header class="title-header data-right-bottom-right-header">
                <h3>公告</h3>
              </header>
              <section class="notice-list-section">
                <ul>
                  <li v-for="item in noticeList" :key="item.id">
                    <el-badge :is-dot="hasReadNotice(item)" class="item" style="float: left;margin-right: 6px;">
                      <img src="../assets/images/gonggao.png" />
                    </el-badge>
                    <h4 @click="onNoticeClick(item)" :title="item.title" style="margin-left: 5px;">{{ item.title }}</h4>
                    <p style="margin-left: 28px;">
                      <i class="el-icon-time"></i>
                      <span>{{ item.pubTime }}</span>
                    </p>
                  </li>
                </ul>
              </section>
            </div>
          </el-main>
        </el-container>
      </el-main>
    </el-container>
  </el-container>
</template>
<script>
import Cookies from "js-cookie"
import HealthIndexExplain from '../layout/institutionComponents/HealthIndexExplain'
import {
  EVENT_DATA_LIST,
  NOTICE_LIST,
  TASK_OBJ,
  LINK_LIST,
  CHART_STATUS_OBJ,
  CHART_OBJ
} from '@/utils/institution/home/data'
import {
  dynamicPieChart,
  radarChart,
  dChart
} from '@/utils/pieChart'
import {
  getEventApi,
  getTaskCountApi,
  getChartApi,
  getNoticeApi,
  updateNoticeApi,
  getStatusApi,
  getEventLinkApi,
  applyReportApi
} from '@/api/institution/home'
import { onStartDownload } from '@/utils/downloadFile'
import { statusHandle } from '@/utils/institution/home/index'
export default {
  components: {
    HealthIndexExplain
  },
  filters: {
    compareVal(val, indexCompareTrend) {
      if (val.indexOf('%') > -1) {
        return val.slice(0, val.length - 1) + '分'
      } else {
        if (indexCompareTrend) {
          return val + '分'
        } else {
          return val
        }
      }
    }
  },
  computed: {
    homeData() {
      return this.$store.getters.instiDept.homeData
    },
    hasData() {
      return this.$store.getters.instiDept.hasData
    },
    chartStyle() {
      const sizeStyle = this.chartSizeChange()
      return sizeStyle
    },
    noticeEventText() {
      return function(item) {
        const logType = Number(item.logType)
        if (logType === 3 || logType === 6 || logType === 7) {
          return 'event-text-hover-cursor'
        }
      }
    },
    // 健康指数表情
    healthIndexEmoji() {
      const { healthIndexScore } = this.chartObj
      if (Number(healthIndexScore) >= 90) {
        // 优
        return require('../assets/images/happy-emoji.png')
      } else if (Number(healthIndexScore) >= 80 && Number(healthIndexScore) < 90) {
        // 良
        return require('../assets/images/liang-emoji.png')
      } else if (Number(healthIndexScore) >= 60 && Number(healthIndexScore) < 80) {
        // 中
        return require('../assets/images/zhong-emoji.png')
      } else if (Number(healthIndexScore) < 60) {
        // 差
        return require('../assets/images/sad-emoji.png')
      }
    },
    trendIcon() {
      const { indexCompareTrend } = this.chartObj
      if (indexCompareTrend === -1)  {
        return 'down'
      } else if (indexCompareTrend === 1) {
        return 'up'
      } else {
        return ''
      }
    },
    linkTextShow() {
      const status = Number(this.chartStatusObj.status)
      if (status === 3) {
        return 'status-text'
      } else if (status === 1 || status === 2 || status === 4) {
        return 'status-text status-link-text'
      }
    },
    // 事件是否显示红点
    hasEventIdot() {
      return function (item) {
        if (item.inTerm) {
          return true
        } else {
          return false
        }
      }
    },
    // 公告是否已读
    hasReadNotice() {
      return function (item) {
        if (item.hasRead) {
          return false
        } else {
          return true
        }
      }
    }
  },
  watch: {
    $route(to, from) {
      // console.log(2)
    },
    async chartDom(newVal, oldVal) {
      // 监听图表dom的变化，当数据改变的时候要重新绘制图表
      const condition = !oldVal
      await this.reRenderDataDom(condition, 'watch chartDom')
    },
    // homeData: {
    //   handler(newVal, oldVal) {
    //     this.$nextTick(() => {
    //       console.log(1)
    //       if (!this.chartDom) {
    //         this.chartDom = document.getElementById('chart-view')
    //       }
    //       if (oldVal && this.hasData && this.chartDom) {
    //         this.chartDom && this.chartSizeChange(this.chartDom)
    //         this.chart = this.$echarts.init(this.chartDom)
    //         clearInterval(this.timer)
    //         this.timer = setInterval(() => {
    //           console.log('watch homeData')
    //           this.draw()
    //         }, 200)
    //         this.draw()
    //       }
    //     })
    //   },
    //   deep: true
    // }
    'homeData.status': async function (newVal, oldVal) {
      const condition = oldVal && this.hasData && this.chartDom
      await this.reRenderDataDom(condition, 'watch homeData.status')
    },
    currentChartIndex(newVal, oldVal) {
      // if (newVal)
    }
  },
  data() {
    return {
      // 机构类型的用户，登录之后看是否有数据显示不同的页面
      // hasData: false,
      eventList: this.$deepClone(EVENT_DATA_LIST),
      // noticeList: this.$deepClone(NOTICE_LIST),
      noticeList: [],
      showHealthIndexDialog: false,
      chart: null,
      angle: 0,
      timer: null,
      chartStatusObj: this.$deepClone(CHART_STATUS_OBJ), // 图表，包含任务和治理报告的状态
      chartObj: this.$deepClone(CHART_OBJ),
      // 任务统计
      taskObj: this.$deepClone(TASK_OBJ),
      // 事件记录
      currentChartIndex: 0,
      chartDom: null,
      isShowPie: true,
      loading: false,
    }
  },
  async created() {
    // console.log(1)
  },
  async activated() {
    this.currentChartIndex = 0
    this.isShowPie = true
    // 要更新一下vuex中instiDept.homeData
    await this.updateStatusData()
    if (this.hasData) {
      // 当首页有数据之后才调有数据的首页的接口
      await this.fetchData()
      const condition = this.chartDom
      await this.reRenderDataDom(condition, 'activated')
    }
  },
  mounted() {
    this.chartDom = document.getElementById('chart-view')
    window.onresize = () => {
      if (this.chartDom) {
        this.chartSizeChange(this.chartDom)
        this.chart.resize()
      }
    }
    this.chart && this.chart.getZr().on('click', (params) => {
      // console.log('点击图表自身')
    })
  },
  destoryed() {
    clearInterval(this.timer)
  },
  deactivated() {
    clearInterval(this.timer)
  },
  methods: {
    // 更新vuex缓存中
    async updateStatusData() {
      let statusRes = await getStatusApi()
      if (statusRes.code === 200) {
        statusHandle(statusRes.data, this.$store.commit)
      }
    },
    // 数据更新之后首页需要重新渲染的dom
    async reRenderDataDom(condition, situation) {
      this.$nextTick(() => {
        if (!this.chartDom) {
          this.chartDom = document.getElementById('chart-view')
        }
        if (condition) {
          this.chartDom && this.chartSizeChange(this.chartDom)
          this.chart = this.$echarts.init(this.chartDom)
          clearInterval(this.timer)
          this.timer = setInterval(() => {
            // console.log(situation)
            this.draw()
          }, 200)
          this.draw()
        }
      })
    },
    // 初始化获取数据
    async fetchData() {
      // 将医疗机构对应的任务、治理报告等数据存在store中
      this.chartStatusObj = this.$store.getters.instiDept.homeData
      // 我的药品库-机构健康指数及雷达图内容
      let chartRes = await getChartApi()
      if (chartRes.code === 200) {
        this.chartObj = chartRes.data || this.$deepClone(this.chartObj)
      }
      // 任务
      let taskRes = await getTaskCountApi()
      if (taskRes.code === 200) {
        this.taskObj = taskRes.data || this.$deepClone(TASK_OBJ)
      }
      // 事件记录
      let eventRes = await getEventApi()
      if (eventRes.code === 200) {
        this.eventList = eventRes.data.rows || []
      }
      // 公告
      let noticeRes = await getNoticeApi()
      if (noticeRes.code === 200) {
        this.noticeList = noticeRes.data.rows || []
      }
    },
    // 图表模块链接文字处理事件
    async linkHandle(item) {
      //
      if (item.status === 1 || item.status === 4) {
        // 查看当前任务详情（进入到元数据上传查看页面）
        this.$router.push(`/drugMetadataUpload/list/matchView/${item.recordId}`)
      } else if (item.status === 2) {
        // 还没有治理报告，立即申请
        // this.loading = true
        let res = await applyReportApi()
        if (res.code === 200) {
          // this.loading = false
          this.$message.success('申请治理报告成功')
        } else {
          // this.loading = false
        }
      }
    },
    // 上传数据
    goToUploadData() {
      // 进入到元数据上传页面并且将上传弹框打开
      this.$router.push('drugMetadataUpload/list')
    },
    // 查看治理报告
    goToViewReport() {
      // 进入到机构治理报告页面
      this.$router.push('institutionGovernanceReport/detail')
    },
    // 点击查看健康指数计算说明
    onDialogClick() {
      this.showHealthIndexDialog = true
    },
    // 事件记录链接点击事件
    async onEventLink(item) {
      let res = null
      if (item.uri.indexOf('publish/download') > -1) {
        // 下载
        res = await getEventLinkApi({ uri: item.uri })
        onStartDownload(res)
      } else {
        // 查看治理报告
        if (item.uri.indexOf('applyId') > -1) {
          Cookies.set('applyId', item.uri.split('applyId=')[1])
        } else {
          Cookies.set('applyId', item.applyId)
        }
        this.goToViewReport()
      }
    },
    // 获取
    // 查看公告
    onNoticeClick(item) {
      this.$prompt(
      `<span style="display: block;
        border: 1px solid #C8D9FA;padding: 20px;min-height: 200px;max-height: 400px;overflow-y: auto;">${item.content}</span>`,
      `${item.title}`, {
        dangerouslyUseHTMLString: true,
        showConfirmButton: false,
        distinguishCancelAndClose: true,
        closeOnClickModal: true,
        showInput: false,
        showCancelButton: false,
        customClass: 'common-alert-dialog',
        callback: action => {
          // this.$message({
          //   type: 'info',
          //   message: `action: ${ action }`
          // })
        },
        beforeClose: async (action, instance, done) => {
          if (action === 'confirm') {
          } else {
            if (!item.hasRead) {
              // 未读才进行调修改的接口
              let res = await updateNoticeApi(item.id)
              this.noticeList.forEach(notice => {
                if (notice.id === item.id) {
                  notice.hasRead = true
                }
              })
            }
            done()
          }
        }
      }).catch(() => {
        // console.log('关闭')
      })
    },
    chartChange(index) {
      if (index === this.currentChartIndex) {
        // 重复点击不执行后面打方法
        return
      }
      this.currentChartIndex = index
      this.isShowPie = !this.isShowPie
      if (!this.isShowPie) {
        // 雷达图
        // chartDom.style.borderRadius = '4px'
        clearInterval(this.timer)
        this.drawRadarChart()
      } else {
        // 环形图
        clearInterval(this.timer)
        this.timer = setInterval(() => {
          // console.log('chartChange')
          this.draw()
        }, 200)
      }
    },
    draw(){
      this.angle = this.angle + 3
      let chartObj = {
        angle: this.angle,
        value: this.chartObj.matchRatio,
        styleObj: { // 样式对象
          color: ['#8E2DE2', '#4B56F2'],
          mainColor: '#4B56F2'
        }
      }
      if (this.chartStatusObj.status === 4) {
        // 显示健康指数和雷达图
        chartObj.value = this.chartObj.healthIndexScore
        chartObj.subtext = '健康指数',
        chartObj.unit = '分'
        if (Number(this.chartObj.healthIndexScore) >= 90) {
          // 优
          chartObj.styleObj.color = ['#38EF7D', '#00AB44']
          chartObj.styleObj.mainColor = '#00AB44'
        } else if (Number(this.chartObj.healthIndexScore) >= 80 && Number(this.chartObj.healthIndexScore) < 90) {
          // 良
          chartObj.styleObj.color = ['#8E2DE2', '#4B56F2']
          chartObj.styleObj.mainColor = '#2D5AFA'
        } else if (Number(this.chartObj.healthIndexScore) >= 60 && Number(this.chartObj.healthIndexScore) < 80) {
          // 中
          chartObj.styleObj.color = ['#FFD700', '#FF8C00']
          chartObj.styleObj.mainColor = '#FF8C00'
        } else if (Number(this.chartObj.healthIndexScore) < 60) {
          // 差
          chartObj.styleObj.color = ['#FF632B', '#EC0000']
          chartObj.styleObj.mainColor = '#EC0000'
        } 
      } else {
        chartObj.unit = '%'
      }
      let option = dynamicPieChart(chartObj)
      // let option = dChart(this.angle)
      this.chart.setOption(option, true)
      //window.requestAnimationFrame(draw);
    },
    drawRadarChart() {
      this.chart = this.$echarts.init(this.chartDom)
      const chartObj = this.chartObj
      const valueData = [chartObj.riskItemScore, chartObj.optimizationItemScore, chartObj.dataMatchItemScore, chartObj.missingDataItemScore]
      let option = radarChart(valueData)
      this.chart.setOption(option, true)
    },
    // 监听浏览器窗口变化，图表大小跟着改变
    chartSizeChange(dom) {
      let windowWidth = window.innerWidth
      let windowHeight = window.innerHeight
      let width = 360
      // console.log(windowHeight)
      // if (windowWidth < 768) {
      //   // xs
      //   width = 220
      // } else if (windowWidth >= 768 && windowWidth < 992) {
      //   // sm
      //   width = 240
      // } else if (windowWidth >= 992 && windowWidth < 1200) {
      //   // md
      //   width = 270
      // } else if (windowWidth >= 1200 && windowWidth < 1620) {
      //   // lg
      //   width = 305
      // } else if(windowWidth >= 1620 && windowWidth < 1920) {
      //   // xl
      //   width = 330
      // } else if (windowWidth >= 1920) {
      //   width = 460
      // }
      if (windowWidth < 768) {
        // xs
        width = 160
      } else if (windowWidth >= 768 && windowWidth < 992) {
        // sm
        width = 230
      } else if (windowWidth >= 992 && windowWidth < 1200) {
        // md
        width = 240
      } else if (windowWidth >= 1200 && windowWidth < 1280) {
        // lg
        width = 250
      } else if(windowWidth >= 1280 && windowWidth < 1536) {
        // xl
        width = 220
      } else if (windowWidth >= 1536 && windowWidth < 1920) {
        width = 250
      } else if (windowWidth >= 1920) {
        width= 340
      }
      let height = width
      let sizeStyle = `width: ${width}px;height: ${height}px`
      dom.style.width = width + 'px'
      dom.style.height = height + 'px'
      dom.style.borderRadiius = "50%"
      return sizeStyle
    },
    // 图表生成方法
    buildChart() {
      let chart = this.$echarts.init(document.getElementById('chart-view'))
      let option = {
        color: ['#4B56F2','#E6E6F3'],
        background: '#fff',
        title:{
          text:'',
          show: false
        },
        series: [
          {
            name: '访问来源',
            type: 'pie',
            radius: ['90%', '70%'],
            startAngle: 270, //起始角度
            avoidLabelOverlap: false,
            hoverAnimation:false,   //关闭放大动画
            selectedOffset:0,     //选中块的偏移量
            label: {
              show: false,
              position: 'center',
              formatter: '{d}%'
            },
            emphasis: {
              label: {
                show: true,
                fontSize: '20',
                fontWeight: 'bold'
              }
            },
            labelLine: {
              show: false
            },
            data: [
              {
                value: 435,
                name: '直接访问',
                selected: true,     //默认选中第一块
                label:{
                  show: true,     //默认显示第一块
                  fontSize: '20',
                  fontWeight: 'bold'
                }
              },
              {
                value: 310,
                name: '邮件营销'
              }
            ]
          }
        ]
      }
      chart.setOption(option)
      window.onresize = function () {
        chart.resize()
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.box-shadow {
  background: #fff;
  /* box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.15); */
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.2);
  border-radius: 13px;
  padding: 20px;
}
/**当父元素设置了overflow：auto，子元素设置box-shadow，左右两边会显示不出来**/
.box-shadow-padding {
  padding: 4px;
}
.title-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  h3 {
    font-size: 18px;
    font-family: SourceHanSansCN-Normal, SourceHanSansCN;
    font-weight: 400;
    color: #000000;
  }
  h4 {
    font-size: 14px;
    color: #666666;
    font-weight: normal;
  }
}
p {
  margin-block-start: 0;
  margin-block-end: 0;
}
/*无数据*/
.home-page-container {
  /* padding-bottom: 20px; */
  padding: 12px 20px 12px;
  .home-page-none-data {
    padding: 0;
    position: absolute;
    width: 100%;
    height: 100%;
    width: calc(100% + 40px);
    height: calc(100% + 24px);
    left: -20px;
    top: -12px;
    .none-data-wrap {
      position: relative;
      width: 100%;
      height: 100%;
      background-color: #fff;
      background-image: url('../assets/images/institution/bj.png');
      background-repeat: no-repeat;
      /* background-position: center center; */
      background-size: cover;
      img {
        position: absolute;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
      .el-button {
        position: absolute;
        top: 220px;
        right: 5.3%;
        width: 240px;
        border-radius: 40px;
        padding: 20px;
        font-size: 24px;
        background-image: linear-gradient(179deg, #4B56F2 28%, #8141EA 100%);
        letter-spacing: 2px;
      }
      span {
        position: absolute;
        right: 5.3%;
        top: 17.2%;
        font-size: 32px;
        color: #333;
        font-family: SourceHanSansCN-Normal;
        letter-spacing: 2px;
      }
    }
  }
}
/*有数据**/
.data-container {
  .data-left {
    padding: 0;
    width: 47% !important;
    height: 100%;
    background-color: inherit;
    .data-left-top {
      /* height: calc(100% - 162px); */
      /* height: 60%;
      min-height: 300px; */
      flex: 1;
      overflow: hidden;
      .chart-explain-div {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: calc(100% - 42px);
      }
      .chart-view-indicator-ul {
        position: absolute;
        right: 20px;
        top: 12%;
        li.chart-view-indicator-li {
          width: 14px;
          height: 14px;
          border-radius: 50%;
          margin-right: 8px;
          background: linear-gradient(179deg, #f6ede1 18%, #aaac9c 100%);
          &.active {
            /* background: linear-gradient(179deg, #faed05 18%, #fa9305 100%); */
            background: linear-gradient(180deg, #C6C6FC 10%, #673ab7 100%);
          }
          &:hover {
            cursor: pointer;
          }
        }
      }
      .chart-view-section {
        /* display: flex;
        justify-content: center;
        align-items: center; */
        margin-top: 4%;
        .chart-view {
          background-color: #fff;
          /* box-shadow: 0 2px 10px 0 rgba(0,0,0,0.18);
          border-radius: 50%; */
          /* width: 45%;
          padding-bottom: 45%; */
          &::after {
            content: '44';
            height: 20px;
            line-height: 30px;
            display: block;
            visibility: hidden;
          }
        }
      }
      .chart-explain-section {
        margin-top: 20px;
        .chart-explain {
          color: #666;
          font-size: 18px;
          font-family: "SourceHanSansCN-Regular";
          .number {
            font-size: 26px;
            color: $topic-color;
          }
          .icon {
            font-size: 26px;
            color: $topic-color;
          }
          .status-text {
            font-family: SourceHanSansCN-Regular;
            font-size: 18px;
            color: #666;
            &:hover {
              color: $topic-color;
              text-decoration: underline;
            }
          }
          .status-link-text {
            cursor: pointer;
          }
        }
      }
    }
    .data-left-bottom {
      /* height: 140px !important; */
      /* height: 140px !important;
      max-height: 140px !important;
      min-height: 100px !important; */
      /* height: 130px !important; */
      height: 20% !important;
      padding: 0 !important;
      margin-top: 12px;
      display: flex;
      justify-content: space-between;
      .data-left-bottom-left {
        width: 49%;
        position: relative;
        /* background: linear-gradient(0deg, #fff, #C6C6F6, #CBE3FB); */
        background: linear-gradient(0deg, #fff, 'gray', #f0f0f0);
        .el-button, span {
          color: #222;
          font-size: 20px;
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-80%, -50%);
        }
        img, .icon-img {
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          right: 10px;
          width: 70px;
          height: 70px;
          background: url('../assets/images/institution/sousuo.png') no-repeat;
        }
        &:hover {
          background-image: linear-gradient(0deg, #5048F1 0%, #6A80FA 100%);
          color: #fff;
          cursor: pointer;
          transition: height 1s;
          /* height: 150px; */
          /* padding: 30px 20px; */
          /* height: 150px; */
          /* margin-top: -5px; */
          & span {
            color: #fff;
          }
          & .icon-img {
            background: url('../assets/images/institution/sousuo-active.png') no-repeat;
          }
        }
      }
      .data-left-bottom-right {
        width: 49%;
        position: relative;
        /* background: linear-gradient(0deg,#fff,#9191c9, #CBE3FB); */
        background: linear-gradient(0deg, #fff, 'gray', #f0f0f0);
        .el-button, span {
          color: #222;
          font-size: 20px;
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-80%, -50%);
        }
        img, .icon-img {
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          right: 10px;
          width: 81px;
          height: 65px;
          background: url('../assets/images/institution/shangchuan.png') no-repeat;
        }
        &:hover {
          background-image: linear-gradient(0deg, #5048F1 0%, #6A80FA 100%);
          color: #fff;
          cursor: pointer;
          & span {
            color: #fff;
          }
          & .icon-img {
            background: url('../assets/images/institution/shangchuan-active.png') no-repeat;
          }
        }
      }
    }
  }
  .data-right {
    background-color: inherit;
    padding: 0 !important;
    margin-left: 1%;
    .data-right-top {
      /* height: calc(100% - 342px); */
      height: 50% !important;
      min-height: 280px !important;
      max-height: 800px;
      /* height: 100% !important; */
      overflow: auto;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      .data-right-top-header {
        line-height: 32px;
      }
      .task-ul {
        display: flex;
        justify-content: center;
        height: 82%;
        .task-li {
          /* width: 33%; */
          width: 25.4%;
          border-radius: 10px;
          /* margin-right: 1%; */
          margin-right: 20px;
          text-align: center;
          height: 100%;
          position: relative;
          /* display: flex;
          flex-direction: column;
          justify-content: flex-end;
          align-items: center; */
          .tag-text-wrap {
            position: absolute;
            top: 15%;
            left: 50%;
            transform: translateX(-50%);
          }
          .tag-text {
            font-size: 16px;
            color: #999;
            margin: 0;
            
            /* padding: 5% 0 40%; */
          }
          .tag-icon {
            width: 60px;
            height: 68px;
            margin: 0 auto;
            position: absolute;
            bottom: 8%;
            left: 50%;
            transform: translateX(-50%);
            img {
              vertical-align: middle;
            }
          }
          &.to-handle-li {
            h3 {
              color: $to-handle-color;
              font-size: 42px;
            }
          }
          &.handleing-li {
            h3 {
              color: $handleing-color;
              font-size: 42px;
            }
          }
          &.finish-li {
            margin-right: 0;
            h3 {
              color: $finish-color;
              font-size: 42px;
            }
          }
        }
      }
    }
    .data-right-bottom {
      /* height: 330px; */
      margin-top: 12px;
      display: flex;
      justify-content: space-between;
      padding: 4px !important;
      .data-right-bottom-left {
        width: 60%;
        padding: 20px 5px 10px 20px;
        .event-list-section {
          height: calc(100% - 24px);
          overflow: auto;
          .event-list-ul {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 8px;
            /* background-color: #f1f1f1; */
            padding: 5px;
            border-radius: 6px;
            transition: background-image 1s ease-in;
            /* min-height: 62px; */
            &:hover {
              /* background-color: #CBE3FB; */
              /* background-image: linear-gradient(0deg,  #CBE3FB 28%, #5058c4 100%); */
            }
            &:first-child {
              margin-top: 5px;
            }
            &:last-child {
              margin-bottom: 0;
            }
            li {
              /* height: 62px; */
              &.event-list-li-left {
                width: 220px;
                ::v-deep.event-badge {
                  .is-dot {
                    right: 8px;
                    top: 8px;
                    border: none;
                  }
                }
              }
              &.event-list-li-right {
                /* background-color: #f1f1f1; */
                border-radius: 13px;
                width: 200px;
                text-align: left;
                line-height: 20px;
                padding: 5px;
                /* position: relative; */
                white-space: nowrap;
                text-overflow:ellipsis;
                overflow: hidden;
                .tag-icon {
                  display: inline-block;
                  width: 26px;
                  height: 26px;
                  border-radius: 50%;
                  background-color: $topic-color;
                  margin-right: 5px;
                  text-align: center;
                  /* position: absolute;
                  top: 50%;
                  transform: translateY(-50%); */
                  .el-icon {
                    color: #fff;
                    font-weight: 600;
                    margin-top: 5px;
                    &:hover {
                      /* cursor: pointer; */
                    }
                  }
                }
              }
              /* p {
                margin-block-start: 0;
                margin-block-end: 0;
              } */
              .username-text {
                font-size: 16px;
                color: #333;
                display: inline-block;
                width: calc(100% - 50px);
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                vertical-align: middle;
              }
              .time-text {
                font-size: 12px;
                color: #999;
                width: calc(100% - 50px);
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                vertical-align: middle;
              }
              .event-text {
                font-size: 14px;
                color: #666;
                /* position: absolute;
                left: 40px;
                top: 50%;
                transform: translateY(-50%); */
                &.event-text-hover-cursor {
                  &:hover {
                    cursor: pointer;
                    text-decoration: underline;
                    margin-top: 3px;
                    color: #4B56F2;
                  }
                }
              }
            }
          }
        }
      }
      .data-right-bottom-right {
        width: 38%;
        padding: 20px 5px 10px 20px;
        .notice-list-section {
          height: calc(100% - 24px);
          overflow: auto;
          ul {
            li {
              height: 62px;
              min-height: 62px;
              margin-bottom: 8px;
              padding: 5px;
              &:first-child {
                margin-top: 5px;
              }
              &:last-child {
                margin-bottom: 0;
              }
              h4 {
                font-weight: normal;
                color: #333;
                font-size: 16px;
                margin-bottom: 5px;
                text-overflow: -o-ellipsis-lastline;
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 1;
                line-clamp: 1;
                -webkit-box-orient: vertical;
                &:hover {
                  cursor: pointer;
                  color: #4B56F2;
                }
              }
              p {
                font-size: 12px;
                color: #999;
                span, i {
                  display: inline-block;
                  margin-right: 5px;
                }
              }
            }
          }
        }
      }
    }
  }
}
@import '../assets/styles/institution/home.scss'
</style>
