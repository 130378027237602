var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.showDialog
    ? _c(
        "el-dialog",
        {
          attrs: {
            title: "健康指数评分说明",
            visible: _vm.dialogVisible,
            "append-to-body": true,
            "custom-class": "health-index-explain-dialog",
            width: "80%",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
            close: _vm.onCloseDialog,
          },
        },
        [
          _c("div", { staticClass: "clearfix" }, [
            _c("section", { staticClass: "fl section-left" }, [
              _c(
                "div",
                { staticClass: "one section-part" },
                [
                  _c("h4", { staticClass: "title" }, [_vm._v("一、安全性")]),
                  _c("p", { staticClass: "subtext" }, [
                    _vm._v("对标： 风险项（权重：30%）"),
                  ]),
                  _c("span", { staticClass: "text" }, [
                    _vm._v(
                      "1.风险项比率 = 含有风险项数据条目数 ÷ 所有药品条目数(包含未匹配和不匹配的药品)"
                    ),
                  ]),
                  _c("span", { staticClass: "text" }, [
                    _vm._v("2.根据风险项比率评分得出安全性分数："),
                  ]),
                  _c(
                    "el-table",
                    {
                      staticClass: "table-class",
                      staticStyle: { width: "45%" },
                      attrs: {
                        data: _vm.riskSectionTableData,
                        border: "",
                        "header-cell-style": { textAlign: "center" },
                        "cell-style": { textAlign: "center" },
                      },
                    },
                    [
                      _c("el-table-column", {
                        attrs: { prop: "percent", label: "风险项比率" },
                      }),
                      _c("el-table-column", {
                        attrs: { prop: "value", label: "得分" },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("div", { staticClass: "three section-part" }, [
                _c("h4", { staticClass: "title" }, [_vm._v("三、完整性")]),
                _c("p", { staticClass: "subtext" }, [
                  _vm._v("对标： 数据缺失（权重：30%）"),
                ]),
                _c("span", { staticClass: "text" }, [_vm._v("1.过滤掉非药品")]),
                _c("span", { staticClass: "text" }, [
                  _vm._v("2.缺失率 = 无数据字段数目÷ 所需上传字段总数"),
                ]),
                _c("span", { staticClass: "text" }, [
                  _vm._v(
                    "3.根据数据缺失项比率评分得出完整性分数： (1 - 缺失率) × 100"
                  ),
                ]),
              ]),
              _c(
                "div",
                { staticClass: "five section-part" },
                [
                  _c("h4", { staticClass: "title title-border" }, [
                    _vm._v("健康指数"),
                  ]),
                  _c("p", { staticClass: "subtext" }, [
                    _vm._v(
                      "健康指数 = 安全性 × 30% + 准确性 × 30% + 完整性 × 30% + 匹配性 × 10%"
                    ),
                  ]),
                  _c(
                    "el-table",
                    {
                      staticClass: "table-class helath-index-table",
                      staticStyle: { width: "45%" },
                      attrs: {
                        data: _vm.healthIndexTableData,
                        border: "",
                        "header-cell-style": { textAlign: "center" },
                        "cell-style": { textAlign: "center" },
                        "cell-class-name": _vm.healthIndexTableCell,
                      },
                    },
                    [
                      _c("el-table-column", {
                        attrs: { prop: "rank", label: "分数区间" },
                      }),
                      _c("el-table-column", {
                        attrs: { prop: "level", label: "等级" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _c("img", { attrs: { src: scope.row.src } }),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          3122562602
                        ),
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]),
            _c("section", { staticClass: "fl section-right" }, [
              _c(
                "div",
                { staticClass: "two section-part" },
                [
                  _c("h4", { staticClass: "title" }, [_vm._v("二、准确性")]),
                  _c("p", { staticClass: "subtext" }, [
                    _vm._v("对标： 优化项（权重：30%）"),
                  ]),
                  _c("span", { staticClass: "text" }, [
                    _vm._v(
                      "1.优化项比率 = 含有优化项数据条目数 ÷ 所有药品条目数(包含未匹配和不匹配的药品)"
                    ),
                  ]),
                  _c("span", { staticClass: "text" }, [
                    _vm._v("2.根据优化项比率评分得出准确性分数："),
                  ]),
                  _c(
                    "el-table",
                    {
                      staticClass: "table-class",
                      staticStyle: { width: "45%" },
                      attrs: {
                        data: _vm.riskSectionTableData,
                        border: "",
                        "header-cell-style": { textAlign: "center" },
                        "cell-style": { textAlign: "center" },
                      },
                    },
                    [
                      _c("el-table-column", {
                        attrs: { prop: "percent", label: "风险项比率" },
                      }),
                      _c("el-table-column", {
                        attrs: { prop: "value", label: "得分" },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("div", { staticClass: "four section-part" }, [
                _c("h4", { staticClass: "title" }, [_vm._v("四、匹配性")]),
                _c("p", { staticClass: "subtext" }, [
                  _vm._v("对标： 数据匹配（权重：10%）"),
                ]),
                _c("span", { staticClass: "text" }, [
                  _vm._v(
                    "1.匹配率 = 已匹配数据条目数 ÷ （总条目数 - 非药品条目数 - 药品库无数据原因未匹 配的条目数）"
                  ),
                ]),
                _c("span", { staticClass: "text" }, [
                  _vm._v(
                    "2.根据数据匹配项比率评分得出匹配性分数： 匹配率 × 100"
                  ),
                ]),
              ]),
            ]),
          ]),
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }